@tailwind base;
@tailwind components;
@tailwind utilities;
@import 'Button.module.css';

:root {
  --primary: #f5a81e;
  --primary-darken: #dc981d;

  --text-color: #000;
  --bg-color: #fff;
  --bg-gradiant: linear-gradient(180deg, #d6d6d6, #d8d8d8, #dedede, #e6e6e6, #eeeeee, #f6f6f6, #fcfcfc, #fefefe);
  --highlight-color: #e8e8e8;
  --error-color: #dd0000;

  --light: #fff;
  --dark: #000;
  --highlight-text: #222;
  --darken-text: #9a9a9a;
  --pattern-bg: #f9f9f9;
  --empty-bg: #191919;
  --muted-color: var(--darken-text);
  --app-layout-gradient: linear-gradient(
    to bottom,
    #d6d6d6 var(--hero-height),
    #d8d8d8,
    #dedede,
    #e6e6e6,
    #eeeeee,
    #f6f6f6,
    #fcfcfc,
    #fff
  );

  --font-size-base: 1rem;
  --font-primary: 'Roboto', 'Helvetica Neue', Arial, sans-serif;
  --font-header: 'Roboto Condensed', sans-serif;

  --default-text-color: var(--dark);
  --default-text-color-highlight: var(--highlight-text);
  --default-header-color: var(--dark);

  --default-h1-size: 2.65rem;
  --default-h2-size: 2rem;
  --default-h3-size: 1.6rem;
  --default-h4-5-size: 1.3rem;
  --default-h4-size: 1rem;
  --default-h5-size: 1rem;
  --default-h6-size: 1rem;

  --btn-color: var(--dark);
  --btn-text: var(--dark);
  --btn-text-highlight: var(--light);
  --btn-font-size: 1rem;
  --btn-color-muted: #c0c0c0;
  --btn-icon-size: 24px;

  --link-color: var(--dark);

  --input-font-size: 0.9rem;
  --input-color: var(--dark);
  --input-radius: 32px;
  --placeholder: rgb(0, 0, 0, 1);
  --placeholder-highlight: rgb(0, 0, 0, 0.4);

  --sidebar-bg: linear-gradient(#ddd, #fff);
  --compact-sidebar-bg: #ddd;
  --compact-sidebar-height: 100px;

  --modal-bg: #ddd;

  --accordion-bg: transparent;
  --accordion-bg-open: #f9f9f9;

  --box-shadow: 0 0 var(--text-color), 0 0 var(--text-color), 0 0px 25px 0px rgba(var(--box-shadow-color), 0.15);
  --drop-shadow: 0 0 var(--text-color), 0 0 var(--text-color), 0 20px 25px -5px rgba(var(--box-shadow-color), 0.1),
    0 8px 10px -6px rgba(var(--box-shadow-color), 0.1);
  --box-shadow-color: 0, 0, 0;

  --transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
  --transition: all 0.42s var(--transition-timing-function);
  --fast-transition: all 0.21s var(--transition-timing-function);

  --container-width: 100%;

  --radius-xs: 4px;
  --radius-sm: 8px;
  --radius-lg: 16px;

  --avatar-bg: #c7c7c7;
  --menu-avatar-bg: #000;
  --menu-avatar-color: #fff;

  --hamburger-color: #000;
  --nav-bg: #212121;
  --nav-line: #b9b9b9;
  --nav-hover: #eaeaea;
  --nav-avatar-size: 42px;
  --avatar-size: 75px;
  --navbar-height: 85px;

  --tags-bg: #212121;
  --tag-bg: #a2a2a2;
  --tag-color: #000;

  --tabs-bg: #212121;
  --active-tab-bg: #a2a2a2;
  --tab-color: #000;

  --scrollbar-color: #777;
  --scrollbar-bg: #111;

  --follow-item-bg: #212121;

  --search-thumbnail-bg: #212121;

  --tooltip-bg: #fff;
  --tooltip-color: #000;

  --underline-offset: 3px;

  --card-bg: linear-gradient(#ddd, #fff);

  --comment-bg: #282828;
  --comment-bg-edit: #444;

  --feed-card-bg: #282828;

  --form-section-bg: #282828;

  --yarl__thumbnails_thumbnail_active_border_color: var(--primary);
}

[data-theme='dark'] {
  --bg-color: var(--dark);
  --text-color: var(--light);
  --highlight-color: #444;

  --highlight-text: #ddd;
  --darken-text: #9a9a9a;
  --pattern-bg: #222;
  --app-layout-gradient: linear-gradient(
    to bottom,
    #292929 var(--hero-height),
    #272727,
    #292929,
    #191919,
    #111111,
    #090909,
    #030303,
    #030303
  );

  --default-text-color: var(--light);
  --default-header-color: var(--light);
  --default-text-color-highlight: var(--highlight-text);

  --btn-color: var(--light);
  --btn-text: var(--light);
  --btn-text-highlight: var(--dark);
  --btn-color-muted: #595959;

  --link-color: var(--light);

  --input-color: var(--light);
  --placeholder: rgb(255, 255, 255, 1);
  --placeholder-highlight: rgb(255, 255, 255, 0.4);

  --sidebar-bg: linear-gradient(#212121, #0b0b0b);
  --compact-sidebar-bg: #212121;
  --modal-bg: #212121;

  --dashboard-sidebar-bg: #0b0b0b;
  --dashboard-sidebar-border: #707070;

  --accordion-bg: transparent;
  --accordion-bg-open: var(--pattern-bg);

  --box-shadow-color: 255, 255, 255;

  --account-nav-color: #000;

  --avatar-bg: #c7c7c7;
  --menu-avatar-bg: #000;
  --menu-avatar-color: #fff;

  --hamburger-color: #fff;

  --card-bg: linear-gradient(#212121, #0b0b0b);
  --card-muted: #707070;

  --input-range-bg: #444;
  --input-range-bg-hover: #fff;
}

html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
}
body {
  margin: 0;
}
main {
  display: block;
}
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}
hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}
pre {
  font-family: monospace, monospace;
  font-size: 1em;
}
a {
  background-color: transparent;
}
abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted;
}
b,
strong {
  font-weight: bolder;
}
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}
small {
  font-size: 80%;
}
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
img {
  border-style: none;
}
button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}
button,
input {
  overflow: visible;
}
button,
select {
  text-transform: none;
}
[type='button'],
[type='reset'],
[type='submit'],
button {
  appearance: button;
  -webkit-appearance: button;
}
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner,
button::-moz-focus-inner {
  border-style: none;
  padding: 0;
}
[type='button']:-moz-focusring,
[type='reset']:-moz-focusring,
[type='submit']:-moz-focusring,
button:-moz-focusring {
  outline: 1px dotted ButtonText;
}
fieldset {
  padding: 0.35em 0.75em 0.625em;
}
legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}
progress {
  vertical-align: baseline;
}
textarea {
  overflow: auto;
}
dialog {
  color: var(--text-color);
}
[type='checkbox'] {
  -webkit-appearance: checkbox; /*override global input -webkit-appearance: none*/
}
[type='radio'] {
  -webkit-appearance: radio; /*override global input -webkit-appearance: none*/
}
[type='checkbox'],
[type='radio'] {
  box-sizing: border-box;
  padding: 0;
}
[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto;
}
[type='search'] {
  appearance: textfield;
  -webkit-appearance: textfield;
  outline-offset: -2px;
}
[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}
details {
  display: block;
}
summary {
  display: list-item;
}
template {
  display: none;
}
[hidden] {
  display: none;
}

html {
  height: 100%;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
  --app-height: calc(100vh - 40px);

  background-color: var(--bg-color);
  color: var(--text-color);
  font-family: var(--font-primary);
  font-size: var(--font-size-base);
  font-weight: 400;
  height: 100%;
  line-height: 1.75;
  margin: 0;
  padding: 0;
}

body.modal-open {
  height: 100dvh;
  overflow: hidden;
  padding-right: 15px;
}

*,
::after,
::before {
  box-sizing: border-box;
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.relative {
  position: relative;
}

body[data-aos-duration='4000'] [data-aos],
[data-aos][data-aos][data-aos-duration='4000'] {
  transition-duration: 4000ms;
}

#root {
  height: 100%;
}

p {
  margin: 0 0 1.5rem 0;
}

p:last-child {
  margin-bottom: 0;
}

a {
  color: var(--link-color);
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

button,
input,
select,
textarea {
  -webkit-appearance: none;
  background: transparent;
  border-radius: 0;
  border: 3px solid var(--input-color);
  color: var(--input-color);
  font-family: inherit;
  font-size: var(--input-font-size);
  outline: 0;
  padding: 0.75rem 1rem 0.75rem;
  transition: var(--fast-transition);
  vertical-align: baseline;
}

select {
  background: transparent;
  padding-top: 0.75rem;
  width: 100%;
}

select::selection {
  background-color: #ff0000;
}

::selection {
  background: var(--primary);
  color: #000;
}

button:focus,
input:focus,
select:focus,
textarea:focus {
  border: 3px solid var(--text-color);
}

[placeholder]::-webkit-input-placeholder {
  color: var(--placeholder);
}
[placeholder]:hover::-webkit-input-placeholder {
  color: var(--placeholder-highlight);
}
[placeholder]:focus::-webkit-input-placeholder {
  color: transparent;
}

[placeholder]::-moz-placeholder {
  color: var(--placeholder);
}
[placeholder]:hover::-moz-placeholder {
  color: var(--placeholder-highlight);
}
[placeholder]:focus::-moz-placeholder {
  color: transparent;
}

[placeholder]:-ms-input-placeholder {
  color: var(--placeholder);
}
[placeholder]:hover:-ms-input-placeholder {
  color: var(--placeholder-highlight);
}
[placeholder]:focus:-ms-input-placeholder {
  color: transparent;
}

.container {
  /* max-width: 720px; */
  margin: 0 auto;
  width: calc(100% - 32px);
}

.container-full-width-mobile {
  width: 100%;
}

.container-full-height {
  height: 100%;
}

.btn-large {
  --btn-font-size: 1rem;
}

svg {
  height: 100%;
  vertical-align: super;
  width: 100%;
}

.highlight {
  animation: blinkingBackground 2.5s cubic-bezier(0.455, 0.03, 0.515, 0.955);
  animation-delay: 0.75s;
}

.yarl__button:focus {
  border: none;
}

.yarl__icon {
  background: rgb(255 255 255);
  border-radius: 50%;
  color: #000;
}

.yarl__thumbnails_container {
  display: none;
}

.flicking-pagination {
  position: absolute;
  left: 0;
  bottom: 10px;
  width: 100%;
  text-align: center;
  z-index: 2;
}

.flicking-pagination-bullets,
.flicking-pagination-scroll {
  font-size: 0;
}

.flicking-pagination-scroll {
  left: 50%;
  transform: translate(-50%);
  white-space: nowrap;
  overflow: hidden;
}

.flicking-pagination-scroll .flicking-pagination-slider {
  transition: 0.2s transform;
}

.flicking-pagination-scroll.flicking-pagination-uninitialized .flicking-pagination-slider,
.flicking-pagination-scroll.flicking-pagination-uninitialized .flicking-pagination-bullet {
  transition: none;
}

.flicking-pagination-bullet {
  display: inline-block;
  width: 8px;
  height: 8px;
  margin: 0 4px;
  border-radius: 50%;
  background-color: rgba(75, 75, 75, 0.5);
  cursor: pointer;
  font-size: 1rem;
}

.flicking-pagination-scroll .flicking-pagination-bullet {
  vertical-align: middle;
  position: relative;
  transition: 0.2s transform;
}

.flicking-pagination-bullet-active {
  background-color: var(--primary);
}

.flicking-pagination-scroll .flicking-pagination-bullet {
  vertical-align: middle;
  position: relative;
  transition: 0.2s transform, 0.2s left;
  transform: scale(0);
}

.flicking-pagination-scroll .flicking-pagination-bullet-active {
  transform: scale(1);
}

.flicking-pagination-scroll .flicking-pagination-bullet-prev,
.flicking-pagination-scroll .flicking-pagination-bullet-next {
  transform: scale(0.66);
}

.flicking-pagination-scroll .flicking-pagination-bullet-prev2,
.flicking-pagination-scroll .flicking-pagination-bullet-next2 {
  transform: scale(0.33);
}

.flicking-arrow-prev,
.flicking-arrow-next {
  position: absolute;
  top: 50%;
  width: 64px;
  height: 64px;
  cursor: pointer;
  transform: translateY(-50%) scale(0.45);
  z-index: 2;
}

.flicking-arrow-prev.is-circle,
.flicking-arrow-next.is-circle {
  background-color: rgb(255 255 255 / 40%);
  border-radius: 50%;
}

.flicking-arrow-disabled.is-circle {
  opacity: 0.25;
}

.flicking-arrow-prev.is-circle::before,
.flicking-arrow-prev.is-circle::after,
.flicking-arrow-next.is-circle::before,
.flicking-arrow-next.is-circle::after {
  background-color: rgb(255, 255, 255, 1);
}

.flicking-arrow-prev {
  left: 0;
}

.flicking-arrow-next {
  right: 0;
}

.flicking-arrow-prev.is-outside {
  left: -74px;
}

.flicking-arrow-next.is-outside {
  right: -74px;
}

.flicking-arrow-prev::before,
.flicking-arrow-prev::after,
.flicking-arrow-next::before,
.flicking-arrow-next::after {
  content: '';
  width: 24px;
  height: 6px;
  position: absolute;
  background-color: var(--primary);
}

.flicking-arrow-prev::before {
  top: 50%;
  left: 22px;
  transform: rotate(-45deg);
  transform-origin: 0% 50%;
}

.flicking-arrow-prev::after {
  top: calc(50% - 4px);
  left: 22px;
  transform: rotate(45deg);
  transform-origin: 0% 50%;
}

.flicking-arrow-next::before {
  top: 50%;
  right: 22px;
  transform: rotate(45deg);
  transform-origin: 100% 50%;
}

.flicking-arrow-next::after {
  top: calc(50% - 4px);
  right: 22px;
  transform: rotate(-45deg);
  transform-origin: 100% 50%;
}

.flicking-arrow-disabled,
.flicking-arrow-disabled {
  cursor: default;
}

.flicking-arrow-disabled::before,
.flicking-arrow-disabled::after,
.flicking-arrow-disabled::before,
.flicking-arrow-disabled::after {
  background-color: #e6e6e6;
}

.flicking-arrow-prev.is-thin::before,
.flicking-arrow-prev.is-thin::after,
.flicking-arrow-next.is-thin::before,
.flicking-arrow-next.is-thin::after {
  height: 3px;
}

.flicking-arrow-prev.is-thin::after,
.flicking-arrow-next.is-thin::after {
  top: calc(50% - 2px);
}

.leaflet-control-attribution.leaflet-control {
  display: none;
}

.leaflet-popup-tip {
  background: var(--follow-item-bg) !important;
}

.leaflet-popup-content {
  margin: 0 !important;
  width: 350px !important;
}

.leaflet-popup-content-wrapper {
  padding: 0 !important;
}

.leaflet-container a.leaflet-popup-close-button {
  color: #fff !important;
}

.leaflet-container a {
  color: var(--primary) !important;
}

@media (min-width: 992px) {
  :root {
    --font-size-base: 1rem;
    --btn-font-size: 1rem;
    --input-font-size: 1.1rem;

    --default-h2-size: 2.5rem;
    --default-h3-size: 2rem;
    --default-h4-5-size: 1.5rem;
    --default-h4-size: 1.2rem;

    --container-width: 960px;
  }

  .container {
    max-width: var(--container-width);
    width: 90%;
  }

  .container-full-width-mobile {
    width: 90;
  }

  .default-container {
    max-width: 960px;
    margin: 0 auto;
    width: 90%;
  }

  .btn-large {
    --btn-font-size: 1.3rem;
  }
}

@media (min-width: 1200px) {
  :root {
    --container-width: 1140px;
    --navbar-height: 105px;

    --default-h1-size: 3.7rem;
    --default-h2-size: 3rem;
    --default-h3-size: 2.6rem;
    --default-h4-5-size: 2rem;
    --default-h4-size: 1.5rem;
  }

  .container {
    width: 100%;
  }

  .default-container {
    width: 100%;
    max-width: 1140px;
  }

  .yarl__thumbnails_container {
    display: block;
  }
}

@media (min-width: 1350px) {
  .container.certificates-container {
    max-width: 960px;
  }
}

@media (min-width: 1600px) {
  :root {
    --font-size-base: 1.2rem;
    --btn-font-size: 1rem;

    --default-h2-size: 3.4rem;

    --container-width: 1440px;

    --nav-avatar-size: 56px;
  }

  .default-container {
    max-width: 1440px;
  }
}

@keyframes fade-in-bg {
  0% {
    opacity: 0.2;
    transform: scale(1.06);
  }
  50% {
    opacity: 1;
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes blinkingBackground {
  0% {
    box-shadow: 0 0 10px 5px transparent;
    background: transparent;
  }
  30% {
    box-shadow: 0 0 10px 5px #1c1c1c;
    background: #181818;
  }
  80% {
    box-shadow: 0 0 10px 5px #1c1c1c;
    background: #181818;
  }
  100% {
    box-shadow: 0 0 10px 5px transparent;
    background: transparent;
  }
}
